const templates = process.env.VUE_APP_PROVIDER === 'tingtong' || process.env.VUE_APP_PROVIDER === 'vmarket' ? [
  {
    title: "Chữ ký",
    route: "setting-signature-template",
    resource: 'template',
    action: 'read',
  },
  {
    title: "Hợp đồng cọc",
    route: "setting-deposit-template",
    resource: 'template',
    action: 'read',
  },
  {
    title: "Hợp đồng thuê",
    route: "setting-contract-template-v2",
    resource: 'template',
    action: 'read',
  },
  {
    title: "Bàn giao tài sản",
    route: "setting-handover-template",
    resource: 'template',
    action: 'read',
  },
  {
    title: "Mẫu hóa đơn",
    route: "setting-invoice-template-v2",
    resource: 'template',
    action: 'read',
  },
] : [
  {
    title: "Chữ ký",
    route: "setting-signature-template",
    resource: 'template',
    action: 'read',
  },
  {
    title: "Hợp đồng cọc",
    route: "setting-deposit-template",
    resource: 'template',
    action: 'read',
  },
  {
    title: "Hợp đồng thuê",
    route: "setting-contract-template-v2",
    resource: 'template',
    action: 'read',
  },
  {
    title: "Bàn giao tài sản",
    route: "setting-handover-template",
    resource: 'template',
    action: 'read',
  },
  {
    title: "Mẫu hóa đơn",
    route: "setting-invoice-template-v2",
    resource: 'template',
    action: 'read',
  },
  {
    title: "Mẫu hợp đồng (cũ)",
    route: "setting-contract-template",
    resource: 'template',
    action: 'read',
  },
  {
    title: "Mẫu hóa đơn (cũ)",
    route: "setting-invoice-template",
    resource: 'template',
    action: 'read',
  },
];

export default [
  {
    header: 'Cài đặt hệ thống',
    resource: 'setting-group',
    action: 'read',
  },
  {
    title: 'Cài đặt chung',
    icon: 'SettingsIcon',
    route: 'settings',
    resource: 'setting',
    action: 'read',
  },
  {
    title: 'Danh mục khác',
    icon: 'SlidersIcon',
    resource: 'setting-other',
    action: 'read',
    children: [
      {
        title: 'Danh mục tài chính',
        route: 'cashbook-list',
        icon: 'BookIcon',
        resource: 'setting-other-finance',
        action: 'read',
        children: [
          {
            title: "Gạch nợ tự động",
            route: 'tingee-list',
            icon: 'WalletIcon',
            resource: 'tingee',
            action: 'read',
          },
          {
            title: "Hình thức thanh toán",
            route: 'cashbook-list',
            icon: 'BookIcon',
            resource: 'cashbook',
            action: 'read',
          },
          {
            resource: 'setting',
            action: 'read',
            title: 'Loại thu chi',
            icon: 'ShuffleIcon',
            route: 'setting-income-expense-type-list',
          },
          {
            title: 'Định mức',
            route: 'quota-list',
            icon: 'BarChartIcon',
            resource: 'quota',
            action: 'read',
          },
        ],
      },

      {
        title: 'Danh mục tài sản',
        route: 'provider-list',
        icon: 'GlobeIcon',
        resource: 'setting-other-inventory',
        action: 'read',
        children: [
          {
            title: 'Nhà cung cấp',
            route: 'provider-list',
            icon: 'GlobeIcon',
            resource: 'provider',
            action: 'read',
          },
          {
            title: 'Kho',
            route: 'warehouse-list',
            icon: 'PackageIcon',
            resource: 'warehouse',
            action: 'read',
          },
          {
            title: 'Loại tài sản',
            route: 'asset-type-list',
            icon: 'TvIcon',
            resource: 'asset-type',
            action: 'read',
          },
          {
            title: 'Lịch sử di chuyển TS',
            route: 'asset-log-list',
            icon: 'TvIcon',
            resource: 'asset',
            action: 'read',
          },
          {
            title: 'Thiết bị IOT',
            route: 'smart-device-list',
            icon: 'TvIcon',
            resource: 'smart-device',
            action: 'read',
          },
        ],
      },

      {
        title: 'Đồng hồ/Công tơ',
        route: 'meter-list',
        icon: 'BatteryChargingIcon',
        resource: 'meter',
        action: 'read',
      },
      {
        title: 'Hotline',
        icon: 'HeadphonesIcon',
        route: 'hotlines',
        resource: 'hotline',
        action: 'read',
      },
    ],
  },
  {
    title: 'Mẫu biểu',
    icon: 'FileTextIcon',
    resource: 'setting',
    action: 'read',
    children: templates,
  },
  {
    title: 'Nhân viên',
    icon: 'UsersIcon',
    resource: 'permission',
    action: 'read',
    children: [
      {
        title: 'Loại tài khoản',
        route: 'role-list',
        icon: 'PocketIcon',
        resource: 'role',
        action: 'read',
      },
      {
        title: 'Người dùng',
        route: 'manager-list',
        icon: 'UsersIcon',
        resource: 'manager',
        action: 'read',
      },
    ],
  },

];
